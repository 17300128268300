@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap);
body {
  font-family: "Quicksand", sans-serif !important;
}
/* Chrome */
::-webkit-scrollbar {
  width: 8px !important;
  height: 0px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(34, 47, 75, 1) !important;
}

::-webkit-scrollbar-track {
  background: rgb(244, 220, 43, 1) !important;
}

.centerFlex {
  display: flex !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.right {
  text-align: right !important;
  justify-content: right !important;
  align-items: right !important;
}

.customLabel {
  display: block !important;
  padding: 6px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  border: 1px solid rgb(217, 217, 217, 63%) !important;
}

.azulC {
  color: rgb(34, 47, 75, 1) !important;
}

.azulBg {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-input {
  border-radius: 10px !important;
}

.ant-select-selector {
  border-radius: 10px !important;
}

.ant-input-password {
  border-radius: 10px !important;
}

.ant-input:focus {
  outline: none !important;
  border-color: rgb(34, 47, 75, 1) !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  outline: none !important;
  border-color: rgb(34, 47, 75, 1) !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper {
  outline: none !important;
  border-color: rgb(34, 47, 75, 1) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  width: 99% !important;
  margin: auto !important;
}

.ant-btn {
  border-radius: 10px !important;
  border: none !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.ant-btn-primary {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-layout.ant-layout-has-sider {
  min-height: 100vh !important;
}

.ant-layout-sider-children {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-menu-dark {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-menu-item-selected {
  background: rgb(244, 220, 43, 1) !important;
}

.ant-menu-item-selected span {
  color: rgb(34, 47, 75, 1) !important;
  font-weight: bold !important;
}

.site-layout-background {
  margin: 10px !important;
  height: 98% !important;
}

.logo {
  padding: 10px !important;
}

.ant-table-wrapper {
  overflow-x: scroll !important;
}

.ant-image-mask {
  border-radius: 20px !important;
}

.ant-carousel .slick-next {
  right: -20px !important;
  border-radius: 20px !important;
  height: 25px !important;
  width: 25px !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}

.ant-carousel .slick-prev {
  left: -20px !important;
  border-radius: 20px !important;
  height: 25px !important;
  width: 25px !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hide {
  display: none !important;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider-zero-width-trigger {
  color: rgb(34, 47, 75, 1) !important;
  background: none !important;
  top: 10px !important;
}

.ant-btn-group button {
  box-shadow: none !important;
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
}

.ant-input-group input {
  width: 99% !important;
}

.ant-input-group button {
  border-radius: unset !important;
}

.tituloProductoProducto {
  text-align: center !important;
  margin: 10px !important;
}
.subtituloProductoProducto {
  text-align: center !important;
}

.colTablaProductos {
  padding: 20px 20px 20px 20px !important;
}

.colSearchProductos {
  padding: 20px 20px 2px 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.colSearchProductos .ant-row {
  width: 100% !important;
}

.colSearchProductos .ant-input-group-wrapper {
  width: 70% !important;
  margin: 0px 10px 0px 10px !important;
}

.colSearchProductosDescripcion {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.colSearchProductosFecha {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.colSearchProductosFecha .ant-picker {
  margin: 0px 8px !important;
}

.mayor {
  color: red !important;
  font-weight: bold !important;
}

.menor {
  color: green !important;
  font-weight: bold !important;
}

.canvas canvas {
  width: 100% !important;
  height: 70vh !important;
}

.canvas .react-pdf__Page__textContent {
  -webkit-transform: translate(-71%, -50%) rotate(0deg) !important;
          transform: translate(-71%, -50%) rotate(0deg) !important;
}

.colButtonGenerarSolicitud {
  text-align: center !important;
  padding: 20px 0px !important;
}

@media only screen and (max-width: 1000px) {
  .colSearchProductos {
    display: block !important;
    text-align: center !important;
    padding: unset !important;
  }

  .colSearchProductos .ant-input-group-wrapper {
    width: 100% !important;
  }

  .colSearchProductosFecha .ant-picker {
    margin: 8px 0px !important;
  }

  .colSearchProductosDescripcion {
    justify-content: center !important;
  }

  .colSearchProductosFecha {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .colSearchProductos {
    display: flex !important;
    justify-content: center !important;
  }

  .colSearchProductos .ant-input-group-wrapper {
    width: 70% !important;
  }

  .colSearchProductosFecha .ant-picker {
    margin: 8px 5px !important;
  }

  .colSearchProductosDescripcion {
    justify-content: center !important;
  }

  .colSearchProductosFecha {
    justify-content: center !important;
  }
}

.ant-input-group input {
  width: 99% !important;
}

.ant-input-group button {
  border-radius: unset !important;
}

.tituloSolicitud {
  text-align: center !important;
  margin: 10px !important;
}
.subtituloSolicitud {
  text-align: center !important;
}

.colTablaSolicitudes {
  padding: 20px 20px 20px 20px !important;
}

.colSearchSolicitudes {
  padding: 20px 20px 2px 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.colSearchSolicitudes .ant-row {
  width: 100% !important;
}

.colSearchSolicitudes .ant-input-group-wrapper {
  width: 70% !important;
  margin: 0px 10px 0px 10px !important;
}

.colSearchSolicitudesDescripcion {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.colSearchSolicitudesFecha {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 20px 20px 20px 20px !important;
}

.colSearchSolicitudesFecha .ant-picker {
  margin: 0px 8px !important;
}

.mayor {
  color: red !important;
  font-weight: bold !important;
}

.menor {
  color: green !important;
  font-weight: bold !important;
}

.canvas canvas {
  width: 100% !important;
  height: 70vh !important;
}

.canvas .react-pdf__Page__textContent {
  -webkit-transform: translate(-71%, -50%) rotate(0deg) !important;
          transform: translate(-71%, -50%) rotate(0deg) !important;
}

.colButtonGenerarSolicitud {
  text-align: center !important;
  padding: 20px 0px !important;
}

.tableExcel {
  margin: 10px 0px !important;
  width: 100% !important;
  border: 1px solid !important;
  text-align: center !important;
}

.tableExcel th {
  border: 1px solid !important;
}

.tableExcel th img {
  width: 100% !important;
}

.tableExcel th table {
  border: unset !important;
  margin: unset !important;
  text-align: center !important;
  width: 100% !important;
}

.tableExcel th table td {
  border: unset !important;
}

.download-table-xls-button {
  width: 150px !important;
  background: #4caf50 !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  padding: 5px !important;
  outline: none !important;
  border: unset !important;
  cursor: pointer !important;
}

@media only screen and (max-width: 1000px) {
  .colSearchSolicitudes {
    display: block !important;
    text-align: center !important;
    padding: unset !important;
  }

  .colSearchSolicitudes .ant-input-group-wrapper {
    width: 100% !important;
  }

  .colSearchSolicitudesFecha .ant-picker {
    margin: 8px 0px !important;
  }

  .colSearchSolicitudesDescripcion {
    justify-content: center !important;
  }

  .colSearchSolicitudesFecha {
    justify-content: center !important;
  }

  .tableExcel {
    display: none !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .colSearchSolicitudes {
    display: flex !important;
    justify-content: center !important;
  }

  .colSearchSolicitudes .ant-input-group-wrapper {
    width: 70% !important;
  }

  .colSearchSolicitudesFecha .ant-picker {
    margin: 8px 5px !important;
  }

  .colSearchSolicitudesDescripcion {
    justify-content: center !important;
  }

  .colSearchSolicitudesFecha {
    justify-content: center !important;
  }

  .tableExcel {
    display: none !important;
  }
}

.logoLogin {
  width: 50% !important;
  margin: 20px auto !important;
}

.formLogin {
  margin: auto !important;
  width: 50% !important;
}

@media only screen and (max-width: 1000px) {
  .logoLogin {
    width: 100% !important;
  }

  .formLogin {
    width: 80% !important;
    margin: 20px auto 20px auto !important;
  }

  .title {
    width: 90% !important;
    text-align: center !important;
    margin: auto !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .containerPadre {
    height: unset !important;
  }
}

h2 {
  margin: unset !important;
}

h5 {
  margin: 0px 0px 20px 0px !important;
}

.logoSingup {
  width: 50% !important;
  margin: 20px auto !important;
}

.formSingup {
  margin: auto !important;
}

.ant-select-selector {
  border-radius: 10px !important;
}

@media only screen and (max-width: 1000px) {
  .logoSingup {
    width: 100% !important;
  }

  .formSingup {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .containerPadre {
    height: unset !important;
  }
}

