.logoLogin {
  width: 50% !important;
  margin: 20px auto !important;
}

.formLogin {
  margin: auto !important;
  width: 50% !important;
}

@media only screen and (max-width: 1000px) {
  .logoLogin {
    width: 100% !important;
  }

  .formLogin {
    width: 80% !important;
    margin: 20px auto 20px auto !important;
  }

  .title {
    width: 90% !important;
    text-align: center !important;
    margin: auto !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .containerPadre {
    height: unset !important;
  }
}
