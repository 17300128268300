@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
body {
  font-family: "Quicksand", sans-serif !important;
}
/* Chrome */
::-webkit-scrollbar {
  width: 8px !important;
  height: 0px !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(34, 47, 75, 1) !important;
}

::-webkit-scrollbar-track {
  background: rgb(244, 220, 43, 1) !important;
}

.centerFlex {
  display: flex !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.right {
  text-align: right !important;
  justify-content: right !important;
  align-items: right !important;
}

.customLabel {
  display: block !important;
  padding: 6px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  border: 1px solid rgb(217, 217, 217, 63%) !important;
}

.azulC {
  color: rgb(34, 47, 75, 1) !important;
}

.azulBg {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-input {
  border-radius: 10px !important;
}

.ant-select-selector {
  border-radius: 10px !important;
}

.ant-input-password {
  border-radius: 10px !important;
}

.ant-input:focus {
  outline: none !important;
  border-color: rgb(34, 47, 75, 1) !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  outline: none !important;
  border-color: rgb(34, 47, 75, 1) !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper {
  outline: none !important;
  border-color: rgb(34, 47, 75, 1) !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  width: 99% !important;
  margin: auto !important;
}

.ant-btn {
  border-radius: 10px !important;
  border: none !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.ant-btn-primary {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-layout.ant-layout-has-sider {
  min-height: 100vh !important;
}

.ant-layout-sider-children {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-menu-dark {
  background: rgb(34, 47, 75, 1) !important;
}

.ant-menu-item-selected {
  background: rgb(244, 220, 43, 1) !important;
}

.ant-menu-item-selected span {
  color: rgb(34, 47, 75, 1) !important;
  font-weight: bold !important;
}

.site-layout-background {
  margin: 10px !important;
  height: 98% !important;
}

.logo {
  padding: 10px !important;
}

.ant-table-wrapper {
  overflow-x: scroll !important;
}

.ant-image-mask {
  border-radius: 20px !important;
}

.ant-carousel .slick-next {
  right: -20px !important;
  border-radius: 20px !important;
  height: 25px !important;
  width: 25px !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}

.ant-carousel .slick-prev {
  left: -20px !important;
  border-radius: 20px !important;
  height: 25px !important;
  width: 25px !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hide {
  display: none !important;
}
