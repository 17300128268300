#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider-zero-width-trigger {
  color: rgb(34, 47, 75, 1) !important;
  background: none !important;
  top: 10px !important;
}

.ant-btn-group button {
  box-shadow: none !important;
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
}
