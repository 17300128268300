.ant-input-group input {
  width: 99% !important;
}

.ant-input-group button {
  border-radius: unset !important;
}

.tituloProductoProducto {
  text-align: center !important;
  margin: 10px !important;
}
.subtituloProductoProducto {
  text-align: center !important;
}

.colTablaProductos {
  padding: 20px 20px 20px 20px !important;
}

.colSearchProductos {
  padding: 20px 20px 2px 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.colSearchProductos .ant-row {
  width: 100% !important;
}

.colSearchProductos .ant-input-group-wrapper {
  width: 70% !important;
  margin: 0px 10px 0px 10px !important;
}

.colSearchProductosDescripcion {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.colSearchProductosFecha {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.colSearchProductosFecha .ant-picker {
  margin: 0px 8px !important;
}

.mayor {
  color: red !important;
  font-weight: bold !important;
}

.menor {
  color: green !important;
  font-weight: bold !important;
}

.canvas canvas {
  width: 100% !important;
  height: 70vh !important;
}

.canvas .react-pdf__Page__textContent {
  transform: translate(-71%, -50%) rotate(0deg) !important;
}

.colButtonGenerarSolicitud {
  text-align: center !important;
  padding: 20px 0px !important;
}

@media only screen and (max-width: 1000px) {
  .colSearchProductos {
    display: block !important;
    text-align: center !important;
    padding: unset !important;
  }

  .colSearchProductos .ant-input-group-wrapper {
    width: 100% !important;
  }

  .colSearchProductosFecha .ant-picker {
    margin: 8px 0px !important;
  }

  .colSearchProductosDescripcion {
    justify-content: center !important;
  }

  .colSearchProductosFecha {
    justify-content: center !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .colSearchProductos {
    display: flex !important;
    justify-content: center !important;
  }

  .colSearchProductos .ant-input-group-wrapper {
    width: 70% !important;
  }

  .colSearchProductosFecha .ant-picker {
    margin: 8px 5px !important;
  }

  .colSearchProductosDescripcion {
    justify-content: center !important;
  }

  .colSearchProductosFecha {
    justify-content: center !important;
  }
}
