h2 {
  margin: unset !important;
}

h5 {
  margin: 0px 0px 20px 0px !important;
}

.logoSingup {
  width: 50% !important;
  margin: 20px auto !important;
}

.formSingup {
  margin: auto !important;
}

.ant-select-selector {
  border-radius: 10px !important;
}

@media only screen and (max-width: 1000px) {
  .logoSingup {
    width: 100% !important;
  }

  .formSingup {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .containerPadre {
    height: unset !important;
  }
}
