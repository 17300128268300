.ant-input-group input {
  width: 99% !important;
}

.ant-input-group button {
  border-radius: unset !important;
}

.tituloSolicitud {
  text-align: center !important;
  margin: 10px !important;
}
.subtituloSolicitud {
  text-align: center !important;
}

.colTablaSolicitudes {
  padding: 20px 20px 20px 20px !important;
}

.colSearchSolicitudes {
  padding: 20px 20px 2px 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.colSearchSolicitudes .ant-row {
  width: 100% !important;
}

.colSearchSolicitudes .ant-input-group-wrapper {
  width: 70% !important;
  margin: 0px 10px 0px 10px !important;
}

.colSearchSolicitudesDescripcion {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.colSearchSolicitudesFecha {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 20px 20px 20px 20px !important;
}

.colSearchSolicitudesFecha .ant-picker {
  margin: 0px 8px !important;
}

.mayor {
  color: red !important;
  font-weight: bold !important;
}

.menor {
  color: green !important;
  font-weight: bold !important;
}

.canvas canvas {
  width: 100% !important;
  height: 70vh !important;
}

.canvas .react-pdf__Page__textContent {
  transform: translate(-71%, -50%) rotate(0deg) !important;
}

.colButtonGenerarSolicitud {
  text-align: center !important;
  padding: 20px 0px !important;
}

.tableExcel {
  margin: 10px 0px !important;
  width: 100% !important;
  border: 1px solid !important;
  text-align: center !important;
}

.tableExcel th {
  border: 1px solid !important;
}

.tableExcel th img {
  width: 100% !important;
}

.tableExcel th table {
  border: unset !important;
  margin: unset !important;
  text-align: center !important;
  width: 100% !important;
}

.tableExcel th table td {
  border: unset !important;
}

.download-table-xls-button {
  width: 150px !important;
  background: #4caf50 !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  padding: 5px !important;
  outline: none !important;
  border: unset !important;
  cursor: pointer !important;
}

@media only screen and (max-width: 1000px) {
  .colSearchSolicitudes {
    display: block !important;
    text-align: center !important;
    padding: unset !important;
  }

  .colSearchSolicitudes .ant-input-group-wrapper {
    width: 100% !important;
  }

  .colSearchSolicitudesFecha .ant-picker {
    margin: 8px 0px !important;
  }

  .colSearchSolicitudesDescripcion {
    justify-content: center !important;
  }

  .colSearchSolicitudesFecha {
    justify-content: center !important;
  }

  .tableExcel {
    display: none !important;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .colSearchSolicitudes {
    display: flex !important;
    justify-content: center !important;
  }

  .colSearchSolicitudes .ant-input-group-wrapper {
    width: 70% !important;
  }

  .colSearchSolicitudesFecha .ant-picker {
    margin: 8px 5px !important;
  }

  .colSearchSolicitudesDescripcion {
    justify-content: center !important;
  }

  .colSearchSolicitudesFecha {
    justify-content: center !important;
  }

  .tableExcel {
    display: none !important;
  }
}
